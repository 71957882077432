import { Part } from "app/models/Part";
import CadFileItem from "./CadFileItem";
import { Button, Card, Modal } from "antd";
import partServices from "app/services/part.service";
import { CloudDownloadOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import "./assemblyPartGroup.module.scss";
import CadFileIcon from "../SVGs/CadFileIcon";

interface DefaultProps {
  parts?: Part[];
}
function AssemblyPartGroup(props: DefaultProps) {
  const { parts } = props;

  const downloadCadFile = async (part: Part) => {
    if (part) {
      try {
        await partServices.downloadCadFile({
          id: part.id,
          name: part.partFileName,
        });
      } catch (error) {}
    }
  };

  const RenderCardFileItem = (part: Part) => {
    return (
      <CadFileItem
        key={`part-${part.id}-${part.parentFileStoreKey}`}
        type={part.cuttingType}
        part={part}
      />
    );
  };

  if (!parts) return <></>;
  return (
    <Card title={<ParentTitle part={parts[0]} />} className="card-part group-bg">
      {parts.map((part: Part) => {
        return RenderCardFileItem(part);
      })}
    </Card>
  );
}

interface ParentTitleDefaultProps {
  part?: Part;
}
function ParentTitle(props: ParentTitleDefaultProps) {
  const { part } = props;
  const [isOpenDxfPreview, setIsOpenDxfPreview] = useState(false);
  const { t } = useTranslation();

  const renderModelPreviewDxfFile = (part: Part | undefined) => {
    if (!part?.parentPreviewUrl) {
      return <></>;
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          maxHeight: "80vh",
        }}
      >
        <img
          style={{ maxHeight: "100%", maxWidth: "100%" }}
          src={part?.parentPreviewUrl}
          alt={`${part?.id}-${part?.partFileName}`}
        />
      </div>
    );
  };

  if (!part) return <></>;

  return (
    <>
      <div className="d-flex flex-column flex-md-row">
        <div className="col flex-col d-flex align-items-center">
          <h5 style={{marginBottom: 0}}>
            <b>{t("assembly")}</b>
          </h5>
        </div>
        <div className="d-flex" style={{ gap: 10 }}>
          <Button
            className="btn-link"
            type="link"
            icon={<EyeOutlined />}
            onClick={setIsOpenDxfPreview.bind(null, true)}
          >
            {t("preview")}
          </Button>
          <Button
            className="btn-link"
            type="link"
          >
            <CadFileIcon />{" "}{part?.parentFileName}
          </Button>
        </div>
      </div>
      <Modal
        open={isOpenDxfPreview}
        width={"calc(60vw - 46px)"}
        onCancel={setIsOpenDxfPreview.bind(null, false)}
        footer={null}
        centered
        className="v3dmodel"
        maskClosable={false}
      >
        {renderModelPreviewDxfFile(part)}
      </Modal>
    </>
  );
}

export default AssemblyPartGroup;
