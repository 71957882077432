import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Input, Table, Tag, notification } from "antd";
import type { ColumnsType } from "antd/es/table";
import CurrencyFormat from "app/components/Format/CurrencyFormat";
import { authSelector } from "app/redux/slides/auth.slide";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { User } from "../../models/User";
import customerServices from "../../services/customer.service";

const CustomerPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [data, setData] = useState<User[]>([]);
  const [dataFilters, setDataFilters] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState<string>("");

  const getData = async () => {
    setLoading(true);
    try {
      const rs = await customerServices.list();
      setData(rs);
      setDataFilters(rs);
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data && searchText) {
      const filters = data.filter((c) =>
        c.email?.toLowerCase().includes(searchText.toLowerCase().trim()) || 
        c.companyName?.toLowerCase().includes(searchText.toLowerCase().trim()) ||
        (`${c.firstName ?? ""} ${c.lastName ?? ""}`).trim()?.toLowerCase().includes(searchText.toLowerCase().trim())
      );
      setDataFilters(filters);
    } else {
      setDataFilters(data);
    }
  }, [searchText]);

  const columns: ColumnsType<User> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: (a: any, b: any) => a.id - b.id,
    },
    {
      title: t("fullName"),
      dataIndex: "name",
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
    },
    {
      title: t("email"),
      dataIndex: "email",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
    },
    {
      title: t("companyName"),
      dataIndex: "companyName",
      sorter: (a: any, b: any) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: t("customer.summary.parts"),
      dataIndex: "numParts",
      sorter: (a: any, b: any) => a.numParts - b.numParts,
    },
    {
      title: t("customer.summary.order"),
      dataIndex: "orderCostSum",
      className: "text-center",
      sorter: (a: any, b: any) => a.orderCostSum - b.orderCostSum,
      render: (data: any) => (
        <div className="text-end fw-bold">
          <CurrencyFormat value={data} />
        </div>
      ),
    },
    {
      title: t("customer.summary.calculations"),
      dataIndex: "numCalculations",
      sorter: (a: any, b: any) => a.numCalculations - b.numCalculations,
    },
    {
      title: t("customer.form.discount"),
      dataIndex: "discount",
      sorter: (a: any, b: any) => a.discount - b.discount,
    },
    {
      title: t("customer.createdAt"),
      dataIndex: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (data) => moment(data).format("L"),
    },
    {
      title: t("loginEnabled"),
      dataIndex: "enabled",
      sorter: (a: any, b: any) => a.enabled - b.enabled,
      render: (data) => {
        if (data) {
          return <Tag color="green">{t("yes")}</Tag>;
        } else {
          return <Tag color="red">{t("no")}</Tag>;
        }
      },
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: User) => (
        <Link to={`/${auth.user.role}/customers/${record.id}`}>
          <EyeOutlined />
        </Link>
      ),
    },
  ];

  const onSearch = (e: any) => {
    setSearchText(e.target.value);
  };
  return (
    <>
      {contextHolder}
      <h3>{t("customer.heading")}</h3>
      <Input
        style={{
          maxWidth: "20vw",
        }}
        className="mb-3"
        prefix={<SearchOutlined />}
        placeholder={t("customer.search") || ""}
        allowClear
        onChange={onSearch}
      />
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `customer-${record.id}`}
        dataSource={dataFilters}
        loading={loading}
      />
    </>
  );
};

export default CustomerPage;
