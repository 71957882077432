import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  Space,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { consents } from "app/constants/consent.constant";
import { configSelector } from "app/redux/slides/config.slide";
import { langSelector } from "app/redux/slides/locale.slide";
import $ from "jquery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Address } from "../../models/Address";
import { DeliveryCost } from "../../models/DeliveryCost";
import { DeliveryOption } from "../../models/DeliveryOption";
import { Part } from "../../models/Part";
import { Price } from "../../models/Price";
import { ListViewMode, Project, ProjectStatus } from "../../models/Project";
import { ShippingOption } from "../../models/ShippingOption";
import { User } from "../../models/User";
import { UserRole } from "../../models/UserRole";
import SelectCustomer from "../../pages/Customer/SelectCustomer";
import { authSelector } from "../../redux/slides/auth.slide";
import { loadingActions } from "../../redux/slides/loading.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import { profileSelector } from "../../redux/slides/user.slide";
import projectServices from "../../services/project.service";
import userServices from "../../services/user.service";
import AddressForm from "../Address/AddressForm";
import CurrencyFormat from "../Format/CurrencyFormat";
import PaymentForm from "../Payment/PaymentForm";
import PaymentFormModal from "../Payment/PaymentFormModal";
import SurfaceTreatmentSurcharge from "../Project/SurfaceTreatmentSurcharge";
import TotalPartPrice from "../Project/TotalPartPrice";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import ListParts from "./ListParts";
import ProjectPrices from "../Project/ProjectPrice";

interface DefaultProps {
  onSubmit?: any;
}

const STANDARD_MAX_WEIGHT = 30;
const STANDARD_MAX_PRICE = 2000;

function CalculationBilling(props: DefaultProps) {
  const { onSubmit } = props;
  const params = useParams();
  const projectId: number = Number(params.projectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const dataProfile = useSelector(profileSelector);
  const lang = useSelector(langSelector);
  const { data, isCalcAll } = useSelector(projectSelector);
  const [formCheckOut] = Form.useForm();
  const [profile, setProfile] = useState<User>(dataProfile);
  const [project, setProject] = useState<Project>(data);
  const [dataAuto, setDataAuto] = useState<Part[]>();
  const [dataManual, setDataManual] = useState<Part[]>();
  const [price, setPrice] = useState<Price>();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isStandardDisabled, setIsStandardDisabled] = useState(false);
  const [showDeliveryNote, setShowDeliveryNote] = useState(false);
  const [shippingOption, setShippingOption] = useState<ShippingOption>(
    ShippingOption.STANDARD
  );
  const [deliveryOption, setDeliveryOption] = useState(
    project.deliveryOption || DeliveryOption.ECONOMY
  );
  const [billingAddress, setBillingAddress] = useState<Address>(
    project.order.billingAddress || {
        id: undefined,
      } ||
      undefined
  );
  const [shippingAddress, setShippingAddress] = useState<Address>(
    project.order.shippingAddress || {
        id: undefined,
      } ||
      undefined
  );
  const [isSameBillingAddress, setIsSameBillingAddress] = useState(true);
  const [addressUpdating, setAddressUpdating] = useState(false);
  const [error, setError] = useState<any>();
  const [customer, setCustomer] = useState<User>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [errorAddress, setErrorAddress] = useState(false);
  const [deliveryCosts, setDeliveryCosts] = useState<any>({});
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isOpenTnC, setIsOpenTnC] = useState(false);
  const config = useSelector(configSelector);
  const [showVat, setShowVat] = useState(true);
  const [disabledSendOffer, setDisabledSendOffer] = useState(false);
  const [countryCode, setCountryCode] = useState<any>(project?.order?.billingAddress?.countryCode);

  const initProjectData = () => {
    const manualParts = project.parts?.filter((p) => !p.auto);
    setDataManual(manualParts);
    if (!project.selfCalculation) {
      formCheckOut.setFieldsValue({
        id: project.id,
        shippingOption: isStandardDisabled
          ? ShippingOption.SPECIAL
          : project.order.shippingOption || ShippingOption.STANDARD,
      });
    }

    let shippingAddressData = Object.assign(
      {},
      project?.order?.shippingAddress
    );
    if (!shippingAddressData) {
      shippingAddressData = {
        // country: config.shopCountryCode,
      };
    }

    let billingAddressData = Object.assign({}, project?.order?.billingAddress);
    if (!billingAddressData) {
      billingAddressData = {
        // country: config.shopCountryCode,
      };
    }

    const isBuyer = auth?.user?.role === UserRole.BUYER;
    if (isBuyer && dataProfile) {
      if (!shippingAddressData.email) {
        shippingAddressData.email = dataProfile?.email;
      }
      if (!shippingAddressData.phone) {
        shippingAddressData.phone = dataProfile?.phone;
      }
      if (!billingAddressData.email) {
        billingAddressData.email = dataProfile?.email;
      }
      if (!billingAddressData.phone) {
        billingAddressData.phone = dataProfile?.phone;
      }
    }

    formCheckOut.setFieldValue("billingAddress", billingAddressData);
    if(isSameBillingAddress) {
      copyBillingAddress("shippingAddress", {
        ...billingAddressData,
        id: project?.order?.shippingAddress?.id
      })
    } else {
      formCheckOut.setFieldValue("shippingAddress", shippingAddressData);
      setCountryCode(project?.order?.shippingAddress?.countryCode);
    }
    // handleChangeShippingAddressCountry(project?.order?.shippingAddress?.country);
  };

  const getProject = async () => {
    dispatch(loadingActions.show(true));
    try {
      const rs: Project = await projectServices.detail(projectId);
      dispatch(projectActions.setProject(rs));
      setProject(rs);
    } catch (error) {
      navigate(`/${auth.user.role}/projects`);
    }
    dispatch(loadingActions.show(false));
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setProfile(rs);
    } catch (error) {}
  };

  const getPrice = async (country: string) => {
    try {
      const rs = await projectServices.getPrice({
        id: projectId,
        shippingOption,
        country,
      });
      setPrice(rs);
    } catch (error) {}
  };

  const updateDeliveryOption = async (deliveryOption: DeliveryOption) => {
    // dispatch(loadingActions.show(true));
    try {
      if (!!project) {
        await projectServices.updateDeliveryOption({
          id: project.id,
          deliveryOption,
        });
        setDeliveryOption(deliveryOption);
      }
    } catch (error) {
      setError(error);
    }
    // dispatch(loadingActions.show(false));
  };

  const initData = async () => {
    try {
      await getMe();
      // await initProjectData();
    } catch (error) {}
  };
  const getDeliveryCosts = async () => {
    try {
      const rs: DeliveryCost = await projectServices.getDeliveryCosts(
        projectId
      );
      setDeliveryCosts(rs);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getProject();
    initData();
    getDeliveryCosts();
  }, []);

  const handleChangeShippingAddressCountry = (countryCode: any) => {
    setCountryCode(countryCode);
    if (countryCode == config.shopCountryCode) {
      getPrice(countryCode);
    } else {
      getPrice("");
    }
  };

  useEffect(() => {
    let countryCode = "";
    if (project) {
      if (formCheckOut.getFieldValue("shippingAddress")?.countryCode) {
        countryCode = formCheckOut.getFieldValue("shippingAddress").countryCode;
      } else if (project?.order?.shippingAddress?.countryCode) {
        countryCode = project?.order?.shippingAddress?.countryCode;
      }
      setCountryCode(countryCode)
      setShowDeliveryNote(shippingOption === ShippingOption.SPECIAL);
    }
  }, [shippingOption, deliveryOption]);

  useEffect(() => {
    handleChangeShippingAddressCountry(countryCode);
  }, [countryCode, shippingOption, deliveryOption])

  useEffect(() => {
    initProjectData();
  }, [project]);

  useEffect(() => {
    const standardDisable = !!(
      project.totalWeight > STANDARD_MAX_WEIGHT ||
      (price?.totalPartPrice && price.totalPartPrice > STANDARD_MAX_PRICE)
    );
    if (!project.selfCalculation) {
      let shippingOption: any = formCheckOut.getFieldValue("shippingOption");

      formCheckOut.setFieldsValue({
        id: project.id,
        shippingOption: standardDisable
          ? shippingOption !== ShippingOption.STANDARD
            ? shippingOption
            : ShippingOption.SPECIAL
          : shippingOption,
      });
      setShippingOption(
        standardDisable
          ? shippingOption !== ShippingOption.STANDARD
            ? shippingOption
            : ShippingOption.SPECIAL
          : shippingOption
      );
    }
    setIsStandardDisabled(standardDisable);
  }, [project, price]);

  useEffect(() => {
    const btnTnC = $("#btn-tnc");
    if (btnTnC) {
      btnTnC.on("click", () => {
        setIsOpenTnC(true);
      });
    }
  }, [lang]);

  const onFormChange = (values: any) => {
    if (!!values.shippingOption) {
      setShippingOption(values.shippingOption);
    }
    if (!!values.deliveryOption) {
      updateDeliveryOption(values.deliveryOption);
    }
    if (!values.agreeTermConditions) {
      formCheckOut.setFieldValue("agreeTermConditions", undefined);
    }
    if(isSameBillingAddress) {
      const ba = { ...formCheckOut.getFieldValue("billingAddress") };
      copyBillingAddress("shippingAddress", {
        ...ba,
        id: shippingAddress?.id,
      });
      setShippingAddress({
        ...ba,
        id: shippingAddress?.id,
      });
    }
  };

  const onIsSameBillingAddress = (e: CheckboxChangeEvent) => {
    setIsSameBillingAddress(e.target.checked);
    if (e.target.checked) {
      const sa = formCheckOut.getFieldValue("billingAddress");
      formCheckOut.setFieldValue("shippingAddress", {
        ...sa,
        id: shippingAddress?.id,
      });
      setCountryCode(sa?.countryCode);
    }
  };
  const onFormAddressSubmit = async (values: any) => {
    setAddressUpdating(true);
    if (isSameBillingAddress) {
      const billingAddress = values.billingAddress;
      const shippingAddress = {
        ...billingAddress,
        id: values.shippingAddress?.id,
      };
      values.shippingAddress = shippingAddress;
    }
    try {
      const rs: Project = await projectServices.updateAddresses(values);
      dispatch(projectActions.setProject(rs));
      setBillingAddress(rs.order.billingAddress);
      setShippingAddress(rs.order.shippingAddress);
      setErrorAddress(false);
    } catch (error) {}
    setAddressUpdating(false);
  };

  const onCheckOut = async (values: any) => {
    // setErrorAddress(false);
    // if (!billingAddress.firstName) {
    //   setErrorAddress(true);
    //   return;
    // }
    dispatch(loadingActions.show(true));
    await onFormAddressSubmit(values);
    // setError(undefined);
    try {
      const isBuyer = auth.user.role === UserRole.BUYER;
      const rs = isBuyer
        ? await projectServices.checkout(values)
        : await projectServices.offerProject({
            ...values,
            email: customerEmail,
          });
      dispatch(projectActions.setProject(rs));

      const showCheckOut =
        isBuyer &&
        config?.paymentEnabled &&
        !rs?.order?.paid &&
        rs?.status == ProjectStatus.SELLER_APPROVED;

      if (showCheckOut) {
        dispatch(loadingActions.show(false));
        setShowPaymentModal(true);
        return;
      }

      if (onSubmit) onSubmit();
    } catch (error) {
      setError(error);
    }
    dispatch(loadingActions.show(false));
  };

  const handleOnClosePaymentModal = () => {
    if (onSubmit) onSubmit();
  };

  const onCompletePayment = () => {
    if (onSubmit) onSubmit();
  };

  const onCustomerSelected = (e: any) => {
    if (typeof e === "string") {
      setCustomer(undefined);
      setCustomerEmail(e);
      formCheckOut.setFieldValue("billingAddress", undefined);
      formCheckOut.setFieldValue("shippingAddress", undefined);
      setBillingAddress({});
      setShippingAddress({});
    } else {
      if (e) {
        const address = {
          projectId,
          billingAddress: {
            ...e.billingAddress,
            id: project.order.billingAddress?.id,
          },
          shippingAddress: {
            ...e.shippingAddress,
            id: project.order.shippingAddress?.id,
          },
        };
        formCheckOut.setFieldValue("billingAddress", address.billingAddress);
        formCheckOut.setFieldValue("shippingAddress", address.shippingAddress);
        // handleChangeShippingAddressCountry(address?.shippingAddress?.country);
        setCountryCode(address?.shippingAddress?.countryCode)
        onFormAddressSubmit(address);
        setCustomer(e);
        setCustomerEmail(e.email);
      } else {
        setCustomer(undefined);
      }
    }
  };

  const onDownloadPreviewOffer = async () => {
    setErrorAddress(false);
    setDisabledSendOffer(true);
    try {
      const validAddress = await formCheckOut.validateFields();
      if (!validAddress) {
        return;
      }
    } catch (error) {
      setDisabledSendOffer(false);
      return;
    }

    const address = {
      projectId,
      billingAddress: formCheckOut.getFieldValue("billingAddress"),
      shippingAddress: formCheckOut.getFieldValue("shippingAddress"),
    };
    formCheckOut.setFieldValue("billingAddress", address.billingAddress);
    formCheckOut.setFieldValue("shippingAddress", address.shippingAddress);
    // handleChangeShippingAddressCountry(address?.shippingAddress?.country);
    setCountryCode(address?.shippingAddress?.countryCode);
    await onFormAddressSubmit(address);

    try {
      setDownloadingPreviewOrder(true);
      await projectServices.finalizeOrder({
        id: project.id,
        shippingOption,
        shippingComment: formCheckOut.getFieldValue("shippingComment"),
        agreeTermConditions: true,
      });
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
    setDisabledSendOffer(false);
  };

  const copyBillingAddress = (name: any, address: any) => {
    formCheckOut.setFieldValue([name, "id"], address.id);
    formCheckOut.setFieldValue([name, "firstName"], address.firstName);
    formCheckOut.setFieldValue([name, "lastName"], address.lastName);
    formCheckOut.setFieldValue([name, "email"], address.email);
    formCheckOut.setFieldValue([name, "phone"], address.phone);
    formCheckOut.setFieldValue([name, "houseNumber"], address.houseNumber);
    formCheckOut.setFieldValue([name, "streetName"], address.streetName);
    formCheckOut.setFieldValue([name, "state"], address.state);
    formCheckOut.setFieldValue([name, "city"], address.city);
    formCheckOut.setFieldValue([name, "country"], address.country);
    formCheckOut.setFieldValue([name, "countryCode"], address.countryCode);
    formCheckOut.setFieldValue([name, "postCode"], address.postCode);
  } 

  const onCheckoutAddressFormChange = () => {
    if (isSameBillingAddress) {
      const ba = formCheckOut.getFieldValue("billingAddress");
      const sa = formCheckOut.getFieldValue("shippingAddress");
      copyBillingAddress("shippingAddress",{
        ...ba,
        id: shippingAddress?.id,
      })
      setShippingAddress({
        ...ba,
        id: shippingAddress?.id,
      });

      if (ba?.countryCode !== sa?.countryCode) {
        setCountryCode(ba?.countryCode)
      }
    }
  };

  if (!project) return <></>;
  return (
    <>
      <Form
        className="app-form"
        form={formCheckOut}
        layout="vertical"
        onValuesChange={onFormChange}
        onFinish={onCheckOut}
        initialValues={{
          id: projectId,
          projectId,
          shippingOption,
          deliveryOption: project.deliveryOption,
        }}
      >
        <Form.Item name="projectId" hidden>
          <Input />
        </Form.Item>
        <div className="row mt-4">
          <div className="col col-12 col-md-9">
            <div className="row">
              {project.selfCalculation && (
                <>
                  <div className="col-2">
                    <h6 className="fw-bold">Customer</h6>
                  </div>
                  <div className="col-10">
                    <SelectCustomer onChange={onCustomerSelected} />
                  </div>
                </>
              )}
              {((auth.user.role === UserRole.BUYER && profile?.companyName) ||
                (auth.user.role === UserRole.SELLER &&
                  customer?.companyName)) && (
                <div className="row mb-2">
                  <div className="col col-2 col-md-2">
                    <label className="fw-bold">{t("companyName")}</label>
                  </div>
                  <div className="col col-10 col-md-10">
                    {auth.user.role === UserRole.BUYER && profile?.companyName}
                    {auth.user.role === UserRole.SELLER &&
                      customer?.companyName}
                  </div>
                </div>
              )}
              <div className="row mb-2">
                <div className="col col-2 col-md-2">
                  <label className="fw-bold">{t("vatNumber")}</label>
                </div>
                <div className="col col-10 col-md-10">
                  {auth.user.role === UserRole.BUYER && profile?.vatId}
                  {auth.user.role === UserRole.SELLER && customer?.vatId}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-6">
                <AddressForm
                  form={formCheckOut}
                  data={billingAddress}
                  title={t("billingAddress")}
                  name="billingAddress"
                  required
                  defaultCountry={
                    formCheckOut.getFieldValue("billingAddress")?.country
                  }
                  onSelected={onCheckoutAddressFormChange}
                />
                <Checkbox
                  onChange={onIsSameBillingAddress}
                  checked={!!isSameBillingAddress}
                >
                  {t("address.same.note")}
                </Checkbox>
              </div>
              <div className="col col-6">
                <AddressForm
                  form={formCheckOut}
                  data={shippingAddress}
                  title={t("shippingAddress")}
                  name="shippingAddress"
                  required={!isSameBillingAddress}
                  disabled={isSameBillingAddress}
                  callbackCountryChanged={handleChangeShippingAddressCountry}
                  defaultCountry={
                    formCheckOut.getFieldValue("shippingAddress")?.country
                  }
                />
              </div>
            </div>
            <div className="row">
              <ListParts project={project} mode={ListViewMode.CHECKOUT} />
            </div>
          </div>
          <div className="col col-12 col-md-3 mt-3 mt-md-0">
            <Card className="b-shadow" title={t("project.orderSummary")}>
              <Space direction="vertical" size={8} className="w-100">
                {/* <p className="sub-l">
                  {t(`deliveryOption.${project.deliveryOption}`)}
                </p> */}
                {auth.user.role === UserRole.BUYER && (
                  <Form.Item label={t("deliveryOption")} name="deliveryOption">
                    <Select
                      options={[
                        {
                          value: DeliveryOption.ECONOMY,
                          label: `${t("deliveryOption.ECONOMY")} - ${
                            deliveryCosts[DeliveryOption.ECONOMY]
                              ?.packageDeliveryTime
                          } ${
                            deliveryCosts[DeliveryOption.ECONOMY]
                              ?.packageDeliveryTime == 1
                              ? t("workingDay")
                              : t("workingDays")
                          }`,
                        },
                        {
                          value: DeliveryOption.STANDARD,
                          label: `${t("deliveryOption.STANDARD")} - ${
                            deliveryCosts[DeliveryOption.STANDARD]
                              ?.packageDeliveryTime
                          } ${
                            deliveryCosts[DeliveryOption.STANDARD]
                              ?.packageDeliveryTime == 1
                              ? t("workingDay")
                              : t("workingDays")
                          }`,
                        },
                        {
                          value: DeliveryOption.FAST,
                          label: `${t("deliveryOption.FAST")} - ${
                            deliveryCosts[DeliveryOption.FAST]
                              ?.packageDeliveryTime
                          } ${
                            deliveryCosts[DeliveryOption.FAST]
                              ?.packageDeliveryTime == 1
                              ? t("workingDay")
                              : t("workingDays")
                          }`,
                        },
                      ]}
                    />
                  </Form.Item>
                )}
                <div>
                  {/* <p className="sub-l">{t("project.shippingOptions")}</p> */}
                  <Form.Item
                    label={t("project.shippingOptions")}
                    name="shippingOption"
                  >
                    <Select
                      // disabled={project?.totalWeight >= STANDARD_MAX_WEIGHT}
                      options={[
                        {
                          value: ShippingOption.STANDARD,
                          label: t("shippingOption.STANDARD"),
                          disabled: isStandardDisabled,
                        },
                        {
                          value: ShippingOption.SELF_PICK_UP,
                          label: t("shippingOption.SELF_PICK_UP"),
                        },
                        {
                          value: ShippingOption.SPECIAL,
                          label: t("shippingOption.SPECIAL"),
                        },
                      ]}
                    />
                  </Form.Item>
                  {showDeliveryNote && (
                    <div>
                      <Alert
                        className="mb-2"
                        message={t("shippingOption.SPECIAL.note")}
                        banner
                      />
                    </div>
                  )}
                  <Form.Item
                    className="mb-1"
                    label={t("project.label.shippingOption")}
                    name="shippingComment"
                  >
                    <TextArea maxLength={255}></TextArea>
                  </Form.Item>
                </div>
                <hr />
                <ProjectPrices project={project} price={price} shippingOption={shippingOption} countryCode={countryCode} onDownloadPdf={onDownloadPreviewOffer} />
                <div className="mt-3">
                  <Form.Item name="id" hidden>
                    <Input />
                  </Form.Item>
                  {!project.selfCalculation && (
                    <Form.Item
                      name="agreeTermConditions"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: t("project.checkout.tnc.required") || "",
                        },
                      ]}
                    >
                      <Checkbox>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t("project.checkout.tnc"),
                          }}
                        ></span>
                      </Checkbox>
                    </Form.Item>
                  )}
                  {error && (
                    <div className="mb-3">
                      <Alert type="error" message={error.message} showIcon />
                    </div>
                  )}
                  <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit" block>
                      {project.selfCalculation
                        ? t("project.sendOutOffer")
                        : t("project.placeYourOrder")}
                    </Button>
                  </Form.Item>
                </div>
                {/* <div className="mt-3 row payment-logo">
                  <img src="/images/paypal-logo.png" />
                  <img src="/images/vorkasse_no_bg.png" />
                  <img src="/images/Visa_2021.svg.png" />
                  <img src="/images/master-card-logo.png" />
                </div> */}
              </Space>
            </Card>
            {
              <Alert
                className="mt-3"
                description={
                  <>
                    <ul className="mb-0">
                      <li>{t("project.checkout.manual.note1")}</li>
                      {((dataManual && !!dataManual.length) ||
                        (!project.manual && project.linkedProject)) && (
                        <li>{t("project.checkout.manual.note2")}</li>
                      )}
                    </ul>
                  </>
                }
                type="info"
                showIcon
                icon={<InfoCircleOutlined />}
              />
            }
          </div>
        </div>
      </Form>
      <PaymentFormModal
        hideButton={true}
        triggerShowModal={showPaymentModal}
        project={data}
        onModalClose={handleOnClosePaymentModal}
      >
        <PaymentForm
          onCompletePayment={onCompletePayment}
          project={data}
        ></PaymentForm>
      </PaymentFormModal>
      <Modal
        className={`modal-body-scroll ${process.env.REACT_APP_BRAND}`}
        width={"90vw"}
        open={isOpenTnC}
        onCancel={setIsOpenTnC.bind(null, false)}
        footer={null}
        destroyOnClose
      >
        <div
          className="tnc"
          dangerouslySetInnerHTML={{
            __html: consents[process.env.REACT_APP_BRAND || ""]?.tnc,
          }}
        ></div>
      </Modal>
    </>
  );
}

export default CalculationBilling;
