import {
  LoadingOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Alert, Button, Input, Modal, Select, Space, notification } from "antd";
import { Compliance } from "app/models/Compliance";
import { CuttingType } from "app/models/CuttingType";
import { Material } from "app/models/Material";
import { MaterialGroup } from "app/models/MaterialGroup";
import { Part } from "app/models/Part";
import { PartViewMode } from "app/models/PartViewMode";
import { ShopType } from "app/models/ShopType";
import { TechDrawingData } from "app/models/TechDrawingData";
import { materialSelector } from "app/redux/slides/material.slide";
import {
  currencySelector,
  profileSelector,
  userActions,
} from "app/redux/slides/user.slide";
import materialServices from "app/services/material.service";
import userServices from "app/services/user.service";
import { HTMLAttributes, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ButtonSelect from "../Button/ButtonSelect";
import LocalizationName from "../Locale/LocalizationName";
import MaterialNumber from "../Material/MaterialNumber";
import RobotCalc from "./RobotCalc";
import { UserRole } from "app/models/UserRole";
import { authSelector } from "app/redux/slides/auth.slide";
const { Option } = Select;
const isShowCompliance =
  process.env.REACT_APP_SHOW_COMPLIANCE &&
  process.env.REACT_APP_SHOW_COMPLIANCE == "true";

const sheetMetalDxfDwgType: any = [
  CuttingType.SHEET_METAL_DWG,
  CuttingType.SHEET_METAL_DXF,
  CuttingType.SHEET_METAL_CONFIGURE,
];
interface DefaultProps extends HTMLAttributes<any> {
  mode?: PartViewMode;
  cuttingType: CuttingType;
  dataPart: any;
  requestingTechDrawingData?: any;
  techDrawingData?: TechDrawingData;
  onMaterialSelectedCallBack: any;
  onSetMaterialbyRobotCallBack?: any;
  part?: Part;
  materialProp?: any;
  thickness?: any;
}

const renderPriceSymbol = (price: number, symbol: string) => {
  const p = Math.floor(price);
  switch (p) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return (
        <>
          <b>€</b>€€€
        </>
      );
    case 6:
    case 7:
    case 8:
      return (
        <>
          <b>€€</b>€€
        </>
      );
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
      return (
        <>
          <b>€€€</b>€
        </>
      );
  }
  return (
    <>
      <b>€€€€</b>
    </>
  );
};

function MaterialSelectModal(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const {
    cuttingType,
    onMaterialSelectedCallBack,
    requestingTechDrawingData,
    techDrawingData,
    part,
    thickness,
  } = props;
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const profile = useSelector(profileSelector);
  const [openMaterial, setOpenMaterial] = useState(false);
  const [search, setSearch] = useState("");
  const [thicknessData, setThicknessData] = useState<any>(
    thickness || part?.thickness
  );
  const [checkFavourite, setCheckFavourite] = useState(false);
  const [sortBy, setSortBy] = useState<any>("name");
  const [filterMaterials, setFilterMaterials] = useState<Material[]>();
  const dataMaterial = useSelector(materialSelector);
  const { groups, sheetMetalGroups, thicknesses, compliances } = dataMaterial;
  const dataMaterials = dataMaterial.materials;
  const [material, setMaterial] = useState<Material | undefined>(
    part?.material
  );
  const [materials, setMaterials] = useState<Material[]>(
    cuttingType === CuttingType.MILLING_AND_TURNING
      ? dataMaterials?.filter(
          (m: Material) => m.shopType !== ShopType.SHEET_METAL && m.active
        )
      : dataMaterials?.filter(
          (m: Material) =>
            m.shopType !== ShopType.MILLING_AND_TURNING && m.active
        )
  );
  const [materialGroup, setMaterialGroup] = useState(
    !!part
      ? groups?.find((x: MaterialGroup) => x.id === part?.materialGroupId) ||
          undefined
      : undefined
  );
  const [thicknessSelected, setThicknessSelected] = useState<any>(
    thicknessData ?? undefined
  );
  const [groupSelected, setGroupSelected] = useState<any[]>([]);
  const [compliancesData, setCompliancesData] = useState<Compliance[]>([]);
  const [complianceSelected, setComplianceSelected] = useState<any[]>([]);
  const { t } = useTranslation();
  const currency = useSelector(currencySelector);
  const [thicknessNotExisted, setThicknessNotExisted] =
    useState<boolean>(false);

  const isCuttingTypeSheetMetalStep =
    cuttingType === CuttingType.SHEET_METAL_STP;
  const isCuttingTypeSheetMetalDxfDwg = sheetMetalDxfDwgType.includes(
    cuttingType
  );
  const materialGroups: MaterialGroup[] =
    cuttingType === CuttingType.MILLING_AND_TURNING ? groups : sheetMetalGroups;

  const showModal = () => {
    setOpenMaterial(true);
  };

  const hideModal = () => {
    setOpenMaterial(false);
  };

  const onMaterialGroupClicked = (groupId?: any) => {
    if (groupSelected.includes(groupId)) {
      setGroupSelected(groupSelected.filter((g) => g !== groupId));
    } else {
      setGroupSelected([...groupSelected, groupId]);
    }
  };
  const onThicknessChanged = (value?: any) => {
    setThicknessSelected(value);
  };

  const onComplianceClicked = (id?: any) => {
    if (complianceSelected.includes(id)) {
      setComplianceSelected(complianceSelected.filter((g) => g !== id));
    } else {
      setComplianceSelected([...complianceSelected, id]);
    }
  };

  const onSearchChanged = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    if (compliances?.length > 0) {
      const data = [...compliances];
      data.sort((a: Compliance, b: Compliance) => {
        const nameA: any = a.name?.toUpperCase();
        const nameB: any = b.name?.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      setCompliancesData([...data]);
    }
  }, [compliances]);
  useEffect(() => {
    if (material) {
      const g = materialGroups.find((g) => g.id === material?.group?.id);
      setMaterialGroup(g);
    }
  }, [material]);

  useEffect(() => {
    if (materials) {
      const filterMaterials = materials.filter(
        (m: any) =>
          (!groupSelected.length || groupSelected.includes(m.group.id)) &&
          (!search ||
            `${m.name}${m.number}`.toLowerCase().indexOf(search.toLowerCase()) >
              -1) &&
          (!thicknessSelected ||
            thicknessNotExisted ||
            (m.thicknesses &&
              m.thicknesses.findIndex(
                (t: any) => thicknessSelected == t.value
              ) > -1)) &&
          (!complianceSelected.length ||
            (m.compliances &&
              m.compliances.findIndex((t: any) =>
                complianceSelected.includes(t.id)
              ) > -1))
      );
      if (sortBy) {
        filterMaterials.sort((a: any, b: any) => {
          return a[sortBy] < b[sortBy] ? -1 : 1;
        });
        setFilterMaterials(filterMaterials);
      } else {
        setFilterMaterials(filterMaterials);
      }
    } else {
      setFilterMaterials(undefined);
    }
  }, [
    materials,
    thicknessNotExisted,
    search,
    thicknessData,
    thicknessSelected,
    groupSelected,
    complianceSelected,
    sortBy,
  ]);

  useEffect(() => {
    setThicknessData(thickness ?? part?.thickness);
    setThicknessSelected(thickness ?? part?.thickness);
  }, [part?.thickness, thickness]);

  useEffect(() => {
    if (thicknesses && thicknesses.length > 0 && thicknessData) {
      const thicknessExisted =
        thicknesses.findIndex((t: any) => t == thicknessData) > -1;
      setThicknessNotExisted(!thicknessExisted);
      if(!thicknessExisted) {
        setThicknessSelected(null);
      }
    }
  }, [thicknesses, thicknessData]);

  const onMaterialSelected = (material: Material) => {
    const group: any =
      materialGroups.find((g) => g.id === material?.group.id) || undefined;
    const data: Material = {
      ...material,
      group,
    };
    setMaterial(data);
    if (isCuttingTypeSheetMetalDxfDwg) {
      onMaterialSelectedCallBack(
        data,
        thicknessSelected ? thicknessSelected : undefined
      );
    } else {
      onMaterialSelectedCallBack(data);
    }
    hideModal();
  };

  const getMaterial = async (id: number) => {
    try {
      const rs: Material = await materialServices.detail(id);
      const group = materialGroups?.find((m) => m.id === rs.groupId);
      onMaterialSelected(rs);
      setMaterialGroup(group);
    } catch (error) {}
  };

  const onSetMaterialbyRobot = () => {
    if (techDrawingData?.materialId) getMaterial(techDrawingData?.materialId);
  };

  useEffect(() => {
    if (techDrawingData && techDrawingData.materialId) {
      getMaterial(techDrawingData.materialId);
    }
  }, [techDrawingData]);

  const onSortByChanged = (value: any) => {
    setSortBy(value);
  };

  const OptionMaterial = (props: { material: Material }) => {
    const m = props.material;
    const active = m.id === material?.id;

    if (
      cuttingType === CuttingType.MILLING_AND_TURNING &&
      m.shopType === ShopType.SHEET_METAL
    ) {
      return <></>;
    }
    if (
      cuttingType !== CuttingType.MILLING_AND_TURNING &&
      (m.shopType === ShopType.MILLING_AND_TURNING || !m.thicknesses?.length)
    ) {
      return <></>;
    }

    const onMaterialClick = () => {
      onMaterialSelected(m);
    };
    const addFavourite = async () => {
      try {
        let rs;
        if (!profile.favouriteMaterialIds.includes(m.id)) {
          rs = await userServices.addFavourite(m.id);
        } else {
          rs = await userServices.removeFavourite(m.id);
        }
        dispatch(userActions.setProfile(rs));
      } catch (error) {}
    };
    if (checkFavourite && !profile.favouriteMaterialIds.includes(m.id))
      return <></>;
    return (
      <div
        className={`row material-item${active ? " active" : ""}`}
        key={`material-${m.id}`}
      >
        <div className="col-auto btn-favourite" onClick={addFavourite}>
          {profile.favouriteMaterialIds.includes(m.id) ? (
            <StarFilled />
          ) : (
            <StarOutlined />
          )}
        </div>
        <div className="col col-4" onClick={onMaterialClick}>
          <LocalizationName localizations={m.group?.localizations} />
        </div>
        <div className="col" onClick={onMaterialClick}>
          {m.name}
          {m.link && (
            <>
              &nbsp;
              <a href={m.link} target="_blank" rel="noreferrer">
                <i className="bi bi-box-arrow-up-right"></i>
              </a>
            </>
          )}
        </div>
        {!process.env.REACT_APP_TECHNICAL_PLASTICS && (
          <>
            <div className="col col-2" onClick={onMaterialClick}>
              <MaterialNumber value={m.number} />
            </div>
            <div className="col-auto text-center" onClick={onMaterialClick}>
              {renderPriceSymbol(m.pricePerKilo, currency.symbol)}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {contextHolder}
      <div className="row align-items-center">
        {(cuttingType !== CuttingType.SHEET_METAL_STP || part) && (
          <div className="col">
            <ButtonSelect
              style={{ width: "100%" }}
              onClick={showModal}
              tooltip={
                material ? (
                  <>
                    {material?.name} (
                    <LocalizationName
                      localizations={materialGroup?.localizations}
                      valueDefault={materialGroup?.name}
                    />
                    )
                  </>
                ) : undefined
              }
            >
              {material ? (
                <>
                  {material?.name} (
                  <LocalizationName
                    localizations={materialGroup?.localizations}
                    valueDefault={materialGroup?.name}
                  />
                  )
                </>
              ) : (
                t("pleaseSelect")
              )}
            </ButtonSelect>
          </div>
        )}
        {cuttingType === CuttingType.SHEET_METAL_STP && !part && (
          <div className="col-auto">
            <LoadingOutlined />
          </div>
        )}
        {material?.link && (
          <div className="col-auto p-1 d-flex align-item-center">
            &nbsp;
            <a
              href={material?.link}
              target="_blank"
              rel="noreferrer"
              style={{ fontSize: "1rem" }}
            >
              <i className="bi bi-box-arrow-up-right"></i>
            </a>
          </div>
        )}
        {requestingTechDrawingData || !!techDrawingData?.materialId ? (
          <div className="col-auto p-1 d-flex align-item-center">
            &nbsp;
            <RobotCalc
              className="pointer"
              loading={requestingTechDrawingData}
              completed={
                !requestingTechDrawingData &&
                techDrawingData?.materialId === material?.id
              }
              onClick={onSetMaterialbyRobot}
              message={t("part.robot.material.message")}
            />
          </div>
        ) : null}
      </div>
      <Modal
        className="selectMaterial"
        open={openMaterial}
        onCancel={hideModal}
        footer={null}
        centered
        destroyOnClose
      >
        <div className="row wrapper-materials">
          <div className="col col-3 col-md-4 group">
            <Space
              direction="vertical"
              className="group-menu px-3 mb-1"
              style={{ width: "100%" }}
              size={0}
            >
              <p className="mb-2 sub-l">
                <strong>{t("material")}</strong>
              </p>
              <div className="">
                <div className="filter-group d-flex flex-wrap gap-2">
                  {materialGroups.map((item) => (
                    <Button
                      key={`group-${item.id}`}
                      type={
                        groupSelected.includes(item?.id) ? "primary" : "default"
                      }
                      onClick={onMaterialGroupClicked.bind(null, item.id)}
                    >
                      <LocalizationName
                        localizations={item?.localizations}
                        valueDefault={item.name}
                      />
                    </Button>
                  ))}
                </div>
              </div>
              {cuttingType !== CuttingType.MILLING_AND_TURNING && (
                <>
                  <p className="mb-2 mt-2 sub-l">
                    <strong>{t("part.form.thickness")}</strong>
                  </p>
                  <div className="filter-thickness">
                    <Select
                      style={{ width: "100%" }}
                      placeholder={
                        thicknessNotExisted
                          ? t("part.thickness.notAvailable", {
                              value: thicknessData,
                            })
                          : t("pleaseSelect")
                      }
                      allowClear
                      value={thicknessSelected}
                      onChange={onThicknessChanged}
                      disabled={isCuttingTypeSheetMetalStep}
                    >
                      {thicknesses.map((t: any) => (
                        <Option key={`thickness-${t}`} value={t}>
                          {t} mm
                        </Option>
                      ))}
                    </Select>
                  </div>
                </>
              )}
              {isShowCompliance && !!compliancesData?.length && (
                <div>
                  <p className="mt-2 sub-l">
                    <strong>{t("part.confirmities")}</strong>
                  </p>
                  <div className="filter-group d-flex flex-wrap gap-2">
                    {compliancesData.map((item: Compliance) => (
                      <Button
                        key={`compliance-${item.id}`}
                        type={
                          complianceSelected.includes(item?.id)
                            ? "primary"
                            : "default"
                        }
                        onClick={onComplianceClicked.bind(null, item.id)}
                      >
                        {item.name}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </Space>
          </div>
          <div className="col col-md p-0 materials">
            <div className="wrapper-filters b-shadow app-form">
              <Input
                placeholder={t("material.search.placeholder") || ""}
                prefix={<SearchOutlined />}
                allowClear
                onChange={onSearchChanged}
              />
              <Button
                className="d-flex align-items-center"
                icon={<StarFilled />}
                type={checkFavourite ? "primary" : "default"}
                onClick={setCheckFavourite.bind(null, !checkFavourite)}
              >
                {t("favorites")}
              </Button>
              <Select
                defaultValue={sortBy}
                allowClear
                placeholder={t("sortBy")}
                style={{ width: "15rem" }}
                onChange={onSortByChanged}
              >
                <Option value="name">{t("sortByName")}</Option>
                <Option value="pricePerKilo">{t("sortByPrice")}</Option>
              </Select>
            </div>
            {thicknessNotExisted && (
              <div className="p-3">
                <Alert
                  type="warning"
                  message={t(
                    `part.manual.${
                      auth.user?.role == UserRole.BUYER ? "buyer" : "seller"
                    }.THICKNESS_NOT_FOUND_IN_MATERIAL`
                  )}
                  showIcon
                  icon={<WarningOutlined />}
                />
              </div>
            )}
            <div className="material-list">
              {(filterMaterials || materials)?.map((item: any) => (
                <OptionMaterial material={item} key={`material-${item.id}`} />
              ))}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MaterialSelectModal;
